// font
@font-face {
  font-family: 'plex-reg';
  src: url('../fonts/IBMPlexMono-Regular.eot');
  src: url('../fonts/IBMPlexMono-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/IBMPlexMono-Regular.woff2') format('woff2'),
       url('../fonts/IBMPlexMono-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

// vars
$breakpoints: (
  'small'  : ( min-width:  767px ),
  'medium' : ( min-width:  992px ),
  'large'  : ( min-width: 1200px )
);

$blue: #002fa7;
$easing: cubic-bezier(1, 0.005, 0.33, 1);

// mixin
@mixin bp($name) {
  @if map-has-key($breakpoints, $name) {
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

// base
html, body {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  margin: 0;
  font-family: "plex-reg";
  font-size: 10px;
  background: $blue;
}

// container
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

// bg
.bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 0%;
  height: 100%;
  z-index: 0;
  transition: width $easing .35s;

  &__image {
    width: 100%;
    height: 100%;
    background-image: url('../images/bg1.png');
    background-repeat: no-repeat;
    background-size: contain ;
    background-position: center;
  }
}

body.loaded .bg {
  width: 55%;
}

// grid
.grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 75%;
  height: 50%;
  z-index: 1;
  background: darken( $blue, 10% );
  padding: 3%;
  overflow: hidden;
  box-shadow: 0px 0px 130px -20px rgba(0,0,0,0.5);
  text-transform: none;
  text-decoration: none;
  transition: box-shadow $easing .25s, width $easing .25s, height $easing .25s;

  @include bp(medium) {
    width: 50%;
    height: 60%;
  }
  @include bp(large) {
    width: 33%;
    height: 50%;
  }

  &:hover {
    box-shadow: 0px 0px 150px -40px rgba(0,0,0,0.5);
    width: 50%;
    height: 50%;

    @include bp(medium) {
      width: calc(50% + 10px);
      height: calc(75% + 10px);
    }

    @include bp(large) {
      width: calc(33% + 10px);
      height: calc(50% + 10px);
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: calc(100% / 5);
    opacity: 0;
    transition: opacity $easing .35s;

    @include bp(medium) {
      height: calc(100% / 7.5);
    }

    @include bp(large) {
      height: calc(100% / 10);
    }
  }

  &__cell {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: calc(100% / 10);
    transition: .2s;
    animation: ease-in-out;
    color: white;

    @include bp(medium) {
      width: calc(100% / 15);
    }

    @include bp(large) {
      width: calc(100% / 20);
    }

    &.active {
      color: red;
    }
  }
}

body.loaded {
  .grid__row {
    opacity: 1;
    transform: translateX(0%);
  }
}

$n-5: 10;
$delay-01: 0.1;
@for $i from 1 through $n-5 {
  $in: $delay-01 * ($i - 1);
  $out: $delay-01 * ($n-5 - $i);

  .grid__row:nth-child(#{$i}) {
    transition-delay: #{$in}s;
  }
}
