@font-face {
  font-family: 'plex-reg';
  src: url("../fonts/IBMPlexMono-Regular.eot");
  src: url("../fonts/IBMPlexMono-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/IBMPlexMono-Regular.woff2") format("woff2"), url("../fonts/IBMPlexMono-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

html, body {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  margin: 0;
  font-family: "plex-reg";
  font-size: 10px;
  background: #002fa7;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 0%;
  height: 100%;
  z-index: 0;
  transition: width cubic-bezier(1, 0.005, 0.33, 1) 0.35s;
}

.bg__image {
  width: 100%;
  height: 100%;
  background-image: url("../images/bg1.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

body.loaded .bg {
  width: 55%;
}

.grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 75%;
  height: 50%;
  z-index: 1;
  background: #002174;
  padding: 3%;
  overflow: hidden;
  box-shadow: 0px 0px 130px -20px rgba(0, 0, 0, 0.5);
  text-transform: none;
  text-decoration: none;
  transition: box-shadow cubic-bezier(1, 0.005, 0.33, 1) 0.25s, width cubic-bezier(1, 0.005, 0.33, 1) 0.25s, height cubic-bezier(1, 0.005, 0.33, 1) 0.25s;
}

@media (min-width: 992px) {
  .grid {
    width: 50%;
    height: 60%;
  }
}

@media (min-width: 1200px) {
  .grid {
    width: 33%;
    height: 50%;
  }
}

.grid:hover {
  box-shadow: 0px 0px 150px -40px rgba(0, 0, 0, 0.5);
  width: 50%;
  height: 50%;
}

@media (min-width: 992px) {
  .grid:hover {
    width: calc(50% + 10px);
    height: calc(75% + 10px);
  }
}

@media (min-width: 1200px) {
  .grid:hover {
    width: calc(33% + 10px);
    height: calc(50% + 10px);
  }
}

.grid__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: calc(100% / 5);
  opacity: 0;
  transition: opacity cubic-bezier(1, 0.005, 0.33, 1) 0.35s;
}

@media (min-width: 992px) {
  .grid__row {
    height: calc(100% / 7.5);
  }
}

@media (min-width: 1200px) {
  .grid__row {
    height: calc(100% / 10);
  }
}

.grid__cell {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(100% / 10);
  transition: .2s;
  animation: ease-in-out;
  color: white;
}

@media (min-width: 992px) {
  .grid__cell {
    width: calc(100% / 15);
  }
}

@media (min-width: 1200px) {
  .grid__cell {
    width: calc(100% / 20);
  }
}

.grid__cell.active {
  color: red;
}

body.loaded .grid__row {
  opacity: 1;
  transform: translateX(0%);
}

.grid__row:nth-child(1) {
  transition-delay: 0s;
}

.grid__row:nth-child(2) {
  transition-delay: 0.1s;
}

.grid__row:nth-child(3) {
  transition-delay: 0.2s;
}

.grid__row:nth-child(4) {
  transition-delay: 0.3s;
}

.grid__row:nth-child(5) {
  transition-delay: 0.4s;
}

.grid__row:nth-child(6) {
  transition-delay: 0.5s;
}

.grid__row:nth-child(7) {
  transition-delay: 0.6s;
}

.grid__row:nth-child(8) {
  transition-delay: 0.7s;
}

.grid__row:nth-child(9) {
  transition-delay: 0.8s;
}

.grid__row:nth-child(10) {
  transition-delay: 0.9s;
}
